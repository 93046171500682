.navbar {
  z-index: 1000;
}

.top {
  .build-up {
    margin-left: -2vw;
  }
  .menu {
    margin-right: -2vw;
  }
}