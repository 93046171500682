@import "../../styles/base.scss";

.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 7.2rem;
    left: 0;
    height: calc(100vh - 7.2rem);
    background-color: $primary-color;
    color: #F0F4C3;
    z-index: 9999;
    width: 100vw;
    overflow-y: auto;

    .top-container {
        display: flex;
        justify-content: flex-end;
        margin: 2rem 8vw 0;

        .exit {
            width: 3vw;
            min-width: 2rem;
            object-fit: contain;
            cursor: pointer;
            padding: 1rem 0 1.8rem 0;
        }
    }
    .page1 {
        display: flex;
        flex-direction: column;
        padding: 2rem 8vw 2rem;
        .left {
            margin-top: 0rem;
            padding-top: 0rem;
            h3 {
                font-size: clamp(2rem, 4vw, 5rem);
                font-family: $heading-font;
                text-transform: uppercase;  
            }
            .web-title {   
                margin-top: 0rem;
                margin-bottom: 4rem;
                padding-top: 0;
                padding-bottom: 0;
            }
            .web-title, .web-title-girlsrising {
                display: none;
            }
            .web-title, .web-title-lgbtq {
                display: none;
            }
            img {
                width: 30vw;
                object-fit: contain;
            }
           
            p {
                margin-top: 3rem;
                font-size: 1rem;
                color: #F0F4C3;
                margin-bottom: 2rem;
            }
        }
        .carousel {

            img {
                pointer-events: auto !important;
                cursor: pointer !important;
                object-fit: contain !important;
                filter: grayscale(100) !important;
            }
            .carousel-slider {
                overflow: inherit;
            }
            .control-dots  {
                transform: translateY(3rem);
            }
            .control-dots {
                .dot {
                    background: $secondary-color !important;
                    opacity: 1 !important;
                    box-shadow: none !important;
                }
                .dot.selected {
                    background: #43A047!important;
                }
            }
            img:hover {
                filter: grayscale(0) !important;
            }
        }
        .button-container {
            overflow: auto;

            a {
                text-decoration: none;

                .button {
                    display: flex;
                    margin: 2.6rem 0 3rem;
                    border-radius: 1000rem;
                    background-color: #9BB7FF;
                    border: 2px solid $secondary-color;
                    height: 54px;

                    p {
                        color: $primary-color;
                        font-family: $p-font;
                        font-weight: 500;
                        margin: auto;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .divider {
        margin: 2rem 0 1rem;
    }
    .page2 {
        display: flex;
        flex-direction: column;
        margin: 0 8vw;

        .team-title {
            margin: 1rem 0;
            text-align: center;
            color: #F0F4C3;
            font-family: $p-font;
            font-size: 6vw;
        }
        .mentors-div, .mentors-div2, .mentors-div3 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 4rem;

                .mentor-grid-item {
                    img {
                        width: 100%;
                    }
                    p {
                        color: $secondary-color;
                        font-weight: bold;
                    }
                    .member_name {
                        padding-top: 0.6rem;
                        text-transform: uppercase;
                    }
                    .member_title {
                        font-weight: 400;
                    }
                }
                
        }
    }
    .graphic-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4rem 0 2rem;

        .graphic {
            width: 68vw;
        }
    }
    .page3 {
        display: flex;
        flex-direction: column;
        margin: 0 8vw 4rem;

        .team-title {
            margin: 1rem 0;
            text-align: center;
            color: #F0F4C3;
            font-family: $p-font;
            font-size: 6vw;
        }
        .mentors-div, .mentors-div2, .mentors-div3 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 4rem;
            justify-content: center;

                .mentor-grid-item {

                    img {
                        width: 100%;
                    }
                    p {
                        color: $secondary-color;
                        font-weight: bold;
                    }
                    .mentor_name {
                        padding-top: 0.6rem;
                        text-transform: uppercase;
                    }
                    .mentor_title {
                        font-weight: 400;
                    }
                }
                
        }
    }
    @media only screen and (min-width: $desktop) {
        .top-container {
            margin: 2rem 4vw 1rem;

            .exit {
                width: 3vw;
                min-width: 2rem;
                object-fit: contain;
                cursor: pointer;
                padding: 1.6rem 0 2.6rem 0;
            }
        }
        .page1 {
            flex-direction: row;
            justify-content: space-between;
            align-items: start;
            padding: 0rem 4vw 4rem 8vw;
            margin-top: 0;
            
            .left {
                width: 36vw;
                h3 {
                    display: none;
                }
                .web-title {
                    display: flex;
                    object-fit: contain;
                    margin-bottom: 2rem;
                    width: 30vw;
                    padding-top: 6rem;
                }
                .web-title-girlsrising {
                    display: flex;
                    object-fit: contain;
                    margin-bottom: 2rem;
                    width: 16vw;
                    padding-top: 6rem;
                }
                .web-title-lgbtq {
                    display: flex;
                    object-fit: contain;
                    margin-bottom: 2rem;
                    width: 30vw;
                    padding-top: 3.8rem;
                }
            }
            .carousel {
                width: 32vw;
            }

            .button-container {
                width: 20vw;
                min-width: 15rem;

                a {
                    text-decoration: none;

                    .button {
                        height: 54px;
                        padding: 0 32px;

                        p {
                            color: $primary-color;
                            font-family: $p-font;
                            margin: auto;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .page2 {
            margin: 0 16vw;

            .team-title {
                font-size: 3vw;
                margin: 4rem 0;
            }
            .mentors-div {
                grid-template-columns: repeat(3, 1fr);
                
            }
        }
        .graphic-cont {
            padding-top: 6rem;
        }
        .page3 {
            margin: 0 16vw 6rem;

            .team-title {
                font-size: 3vw;
                margin: 4rem 0;
            }
            .mentors-div2 {
                grid-template-columns: repeat(2, 1fr);
                margin: 0 12vw;
            }
            .mentors-div3 {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

p {
  text-align: justify;
  text-justify: inter-word;
  color: #F0F4C3;
}

    

    

