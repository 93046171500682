@import "../../styles/base.scss";

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  padding: 4rem 10vw;
  border-top: solid 2px $primary-color;
  border-bottom: solid 2px $primary-color;
  text-align: left;
  font-family: $p-font;
  background-repeat: repeat;
  background-image: url("../../assets/images/website_texture.png");
  overflow-x: hidden;

  .big-title {
    display: flex;
    align-items: center;
    flex-direction: row;

    .blocks {
        margin-right: -2vw;
    }

    .row {
      display: flex;
      align-items: center;
      flex-direction: row;

      .square {
        border: $primary-color solid 1px;
        height: 5.5vw;
        width: 5.5vw;
        margin: -0.5px;
      }
      .rect {
        display: flex;
        align-items: center;
        position: inherit;
        height: 5.5vw;
        width: calc(11vw + 2px);
      }

      .circle {
        border: $primary-color solid 1px;
        border-radius: 50%;
        background-color: #9bb7ff;
        height: 5.5vw;
        width: 5.5vw;
        margin: -0.5px;
      }

      .oval {
        border: $primary-color solid 1px;
        background-color: #43a047;
        height: 5.5vw;
        width: calc(11vw + 2px);
        border-radius: 25%/50%;
      }

      .circle {
        animation-name: trans3;
        animation-duration: 4s;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-direction: normal;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
      }

      

      #oval1 {
        animation-name: trans2;
        animation-duration: 4s;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-direction: normal;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
      }

  

      #applyoval2 {
        width: 11vw;
        animation-name: trans6;
        animation-duration: 4s;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-direction: normal;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
      }

      @keyframes trans6 {
        0% {
          width: 11vw;
          border-radius: 25%/50%;
        }
        15% {
          transform: translate3d(5.5vw, 0, 0);
          width: 5.5vw;
          border-radius: 100%;
        }
        35% {
          width: 5.5vw;
          transform: translate3d(0, 0, 0);
          border-radius: 100%;
        }
        55% {
          width: 5.5vw;
          transform: translate3d(5.4vw, 0, 0);
          border-radius: 100%;
        }
        70% {
          transform: translate3d(0, 0, 0);
          border-radius: 25%/50%;
          width: 11vw;
        }
      }

  
    }

    h2 {
      text-align: right;
    }
  }

  h3 {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.8rem;
    margin-right: 0;
  }

  .text-title {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;

    img {
      height: 20px;
      margin-right: 0.5rem;
    }
  }

  .text-block1,
  .text-block2 {
    width: 60vw;

    p {
      margin-top: 0.5rem;
    }
  }

  .text-block2 {
    margin-left: auto;
    margin-right: 10vw;
  }

  .subtitle1, .subtitle2 {
    margin-top: 2rem;
    margin-right: 10vw;
  }

  .testimony1, .testimony2 {
    margin-right: 10vw;
  }

  .social-proof {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 10vw;

    img {
        width: 36vw;
        filter: grayscale(100);
    }
  }

  .bottom-section {
      margin-right: 10vw;
  }

  .pill {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

    a {
        font-family: $p-font;
        font-weight: 500;
        font-size: 3vh;
        border-radius: 40px;
        border: 1.5px solid #424242;
        padding: 0.5rem 1.25rem;
        text-decoration: none;
        color: #424242;
        background-color: #9bb7ff;
    }
  }   
}

@media only screen and (min-width: $mobile) {
  .grid {
    grid-template-columns: repeat(12, 1fr);
    padding: 7.5rem 8vw;
    grid-row-gap: 3.75rem;

    .big-title {
      grid-column-start: 1;
      grid-column-end: end;

      .blocks {
          margin-right: 0;
      }

      .row {
        .square {
          border: $primary-color solid 2px;
          margin: -1px;
        }

        .circle {
          border: $primary-color solid 2px;
          margin: -1px;
        }

        .oval {
          border: $primary-color solid 2px;
          margin: -1px 0px;
          border-radius: 25%/50%;
        }
      }
    }

    h3,
    p {
      font-size: 1.2rem;
    }

    .text-block1 {
      grid-column-start: 2;
      grid-column-end: span 4;
      width: auto;

      p {
        margin-top: 1rem;
      }

      .text-title {
        display: flex;
        align-items: center;
        flex-direction: row;

        img {
          height: 48px;
          margin-right: 2rem;
        }
      }
    }

    .text-block2 {
      grid-column-start: 8;
      grid-column-end: span 4;
      width: auto;
      margin: 0;

      p {
        margin-top: 1rem;
      }
    }

    .text-title {
      display: flex;
      align-items: center;
      flex-direction: row;

      img {
        height: 48px;
        margin-right: 2rem;
      }
    }

    .subtitle1 {
      grid-row-start: 5;
      grid-column-start: 2;
      grid-column-end: span 10;
      margin: 0;
    }

    .testimony1 {
      grid-row-start: 6;
      grid-column-start: 2;
      grid-column-end: span 4;
      margin: 0;
    }

    .testimony2 {
      grid-row-start: 6;
      grid-column-start: 8;
      grid-column-end: span 4;
      margin: 0;
    }

    .subtitle2 {
        grid-row-start: 8;
        grid-column-start: 2;
        grid-column-end: span 10;
        margin: 0;
        text-align: center;
    }

    .social-proof {
        grid-row-start: 9;
        grid-column-start: 1;
        grid-column-end: span 12;
        margin: 0;
        justify-content: space-evenly;

        img {
            width: 16vw;
        }
    }

    .bottom-section {
        display: none;
    }
  }
}

@media only screen and (min-width: 600px) {
  .grid {
    .big-title {
      .blocks {
        .row {
          .rect {
            width: calc(11vw + 4px);
          }
        }
      }
    }
  }
  
}

@media only screen and (min-width: $desktop) {
  .grid {
    h3,
    p {
      font-size: 1.5rem;
    }
  }

}
