@import '../../styles/base.scss';

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #E7E5D1;
    padding: 4rem $desktop-padding;
    border-top: $desktop-stroke;
    background-image: url("../../assets/images/website_texture.png");

    .footer-left {
        display: flex;
        flex-direction: row;
        font-family: $sub-font;
        align-items: center;

        p {
            margin-right: 1rem;
        }
        .footer-logo {
            display: flex;
            flex-direction: row;

            .dco-footer-logo {
                margin-left: 1rem;
            }
        }
    }
    .footer-right {
        text-transform: uppercase;

        a {
            font-family: $link-font;
            text-decoration: underline;
            color: #000;
        }
    }

    @media only screen and (max-width: $desktop) {
        .footer-left {
            .footer-logo {
                .heart-icon {
                    height: 0.75rem;
                }
                .dco-footer-logo {
                    height: 0.75rem;
                    margin-top: 0.3rem;
                }
            }
        }
    }

    @media only screen and (max-width: $mobile) {
        padding: 2rem 6vw;
        border-top: $mobile-stroke;

        .footer-left {
            p {
                margin-right: 0.2rem;
                font-size: 12px;
            }
            .footer-logo {
                display: flex;
                flex-direction: row;
                align-items: center;
    
                .heart-icon {
                    height: 0.5rem;
                }
                .dco-footer-logo {
                    margin-left: 0.5rem;
                    height: 0.5rem;
                }
            }
        }
        .footer-right {
            margin-bottom: 0.2rem;
    
            a {
                text-decoration: underline;
                color: #424242;
                font-size: 10px;
            }
        }
    }
}










