@import '../../styles/base.scss';

.themes {
    background-image: url(../../assets/images/website_texture.png);
    padding: 0 8vw;

    h2 {
        padding-top: 7.5rem;
        line-height: 90%;
        text-align: center;
    }
    > p {
        margin: 60px 0;
        text-align: left;
    }
    &__wrapper {
        padding-bottom: 120px;
    }
    &__section {
        margin: 0;
        display: grid;
        grid-template-columns: 30vw 1fr;
        &__header {
            height: 30vw;
            &__title {
                height: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: $desktop-stroke;
                border-right: none;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: 600;
                padding: 0 12px;
                text-align: center;
                > span {
                    font-size: 1.5rem;
                }
            }
            &__detail {
                height: calc(50% + 0px);
                display: grid;
                grid-template-columns: 1fr 1fr;
                > div:first-child > img {
                    width: 100%;
                }
                > div:last-child {
                    height: calc(100% - 5.5px);
                    width: 100%;
                    border-left: $desktop-stroke;
                    border-bottom: $desktop-stroke;
                    ul {
                        width: 100%;
                        height: 50%;
                        li {
                            list-style: none;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &:first-child {
                                border-bottom: $desktop-stroke;
                            }
                            a {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
        &__description {
            height: calc(30vw - 2rem - 0px);
            padding: 1rem 3rem;
            border: $desktop-stroke;
            display: flex;
            text-align: left;
            align-items: center;
            ul li {
                font-size: 1.2rem;
                margin-bottom: 1rem;
            }
        }
        &__spacer-hidden {
            width: 100%;
            display: none;
        }
        .shapes {
            width: 30vw;
            height: calc(5vw + 4px);
            margin: -2px 0;
            padding: 0;
            display: flex;
            .circle,
            .square,
            .oval {
                height: 5vw;
                width: 5vw;
            }
            .rect {
                height: calc(5vw + 4px);
                width: calc(10vw + 6px);
            }
            #newoval1 {
                animation-name: trans5;
                animation-duration: 4s;
                animation-timing-function: linear;
                animation-delay: 0s;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-fill-mode: none;
                animation-play-state: running;
            }
            @keyframes trans5 {
                0% {
                  width: calc(10vw - 2px);
                  border-radius: 25%/50%;
                }
                15% {
                  transform: translate3d(0, 0, 0);
                  width: calc(5vw - 2px);
                  border-radius: 100%;
                }
                35% {
                  width: calc(5vw - 2px);
                  transform: translate3d(5vw, 0, 0);
                  border-radius: 100%;
                }
                55% {
                  width: calc(5vw - 2px);
                  transform: translate3d(0, 0, 0);
                  border-radius: 100%;
                }
                70% {
                  border-radius: 25%/50%;
                  width: calc(10vw - 2px);
                }
                100% {
                    border-radius: 25%/50%;
                    width: calc(10vw - 2px);
                }
            }
        }
        .hidden {
            display: none;
        }
        .right {
            flex-direction: row-reverse;
            margin: -2px 0 -2px -1px;
            > #circle2 {
                background-color: $accent-color-1;
            }
        }
        &:nth-child(even) {
            grid-template-columns: 1fr 30vw;
            .themes__section {
                &__header {
                    border: none;
                    &__title {
                        border-left: none;
                        border-right: $desktop-stroke;
                    }
                    &__detail {
                        > div:last-child {
                            border-right: $desktop-stroke;
                            border-left: none;
                            grid-row: 1;
                        }
                    }
                }
                &__description {
                    grid-row: 1;
                }
            }
        }
    }

    @media only screen and (max-width: $desktop) {
        &__section__header__title > span{
            font-size: 1.3rem;
        }
        ul li {
            font-size: 0.9rem;
        }
    }

    @media only screen and (max-width: 760px) {
        padding: 0 10vw;

        h2 {
            padding-top: 4rem;
        }

        > p {
            margin: 30px 0;
            text-align: left;
        }
        &__section {
            grid-template-columns: 80vw;
            &__header {
                height: calc(100vw - 20vw);
                margin-bottom: 2px;
                &__title {
                    border: $mobile-stroke;
                }
                &__detail {
                    width: calc(100% - 2px);
                    height: calc(50% + 6px);
                    > div:last-child {
                        border: $mobile-stroke;
                        border-top: none;
                        border-bottom: none;
                        ul li:first-child {
                            border-bottom: $mobile-stroke;
                        }
                    }
                }
            }
            &__description {
                border: $mobile-stroke;
            }
            &:nth-child(even) {
                grid-template-columns: 80vw;
                .themes__section {
                    &__header {
                        height: calc(100vw - 20vw);
                        &__title {
                            border-left: $mobile-stroke;
                            border-right: $mobile-stroke;
                        }
                        &__detail {
                            > div:first-child > img {
                                padding-left: 2px;
                            }
                            > div:last-child {
                                border-left: $mobile-stroke;
                                border-right: $mobile-stroke;
                            }
                        }
                    }
                    &__description {
                        grid-row: 2;
                    }
                }
            }
            .shapes {
                width: calc(100% - 0.5px);
                height: 14vw;
                margin: 0;
                .rect {
                    width: 28vw;
                    height: 14vw;
                }
                .oval {
                    height: calc(14vw - 1px);
                    width: 26vw;
                    border: $mobile-stroke;
                }
                .square {
                    height: 14vw;
                    width: 14vw;
                    border: $mobile-stroke;
                }
                .circle {
                    height: 14vw;
                    width: 14vw;
                    border: $mobile-stroke;
                }
            }
            #newoval1 {
                animation-name: trans5;
                animation-duration: 4s;
                animation-timing-function: linear;
                animation-delay: 0s;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-fill-mode: none;
                animation-play-state: running;
            }
            @keyframes trans5 {
                0% {
                  width: calc(26vw - 2px);
                  border-radius: 25%/50%;
                }
                15% {
                  transform: translate3d(0, 0, 0);
                  width: calc(13vw - 2px);
                  border-radius: 100%;
                }
                35% {
                  width: calc(13vw - 2px);
                  transform: translate3d(13vw, 0, 0);
                  border-radius: 100%;
                }
                55% {
                  width: calc(13vw - 2px);
                  transform: translate3d(0, 0, 0);
                  border-radius: 100%;
                }
                70% {
                  border-radius: 25%/50%;
                  width: calc(26vw - 2px);
                }
                100% {
                    border-radius: 25%/50%;
                    width: calc(26vw - 2px);
                }
            }
        }
    }
    @media only screen and (max-width: $mobile) {
        &__wrapper {
            padding-bottom: 60px;
        }
        &__section__description {
            height: 220px;
            padding: 1rem 2rem;

            ul li {
                margin-bottom: 0.5rem;
            }
        }
        ul li {
            font-size: 0.8rem;
        }
    }
}