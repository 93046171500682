@import "../../styles/base.scss";

.showcase {
  background: $primary-color;
  padding: 6rem 0;
  @media only screen and (max-width: 1024px) {
    padding: 4rem 0;
  }
  @media only screen and (max-width: $mobile) {
    padding: 2rem 0;
  }
  overflow-x: hidden;

  .header-container {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    .header-showcase {
      width: 105vw;
      object-fit: contain;
    }
    @media only screen and (max-width: $mobile) {
      margin-top: 2rem;
    }
  }
  h3 {
    font-family: $p-font;
  }
}

.showcase__bottom {
  padding: 0 8vw;
}

.showcase-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -2vw;

  h2 {
    text-align: center;
    color: $secondary-color;
    transform: translateY(-1rem);
    color: #f0f4c3;

    @media only screen and (max-width: $mobile) {
      transform: translateY(-0.5rem);
    }
  }
  .left-image {
    width: 20vw;
    object-fit: contain;
  }
  .right-image {
    width: 20vw;
    object-fit: contain;
  }
}

.showcase__illustration__box {
  box-sizing: border-box;

  aspect-ratio: 1 / 1;
  position: relative;
  border: 2px solid $secondary-color;
  margin: -1px;
}
.showcase__illustration__box__wrapper {
  display: flex;
  height: 100%;
  width: 0;
  position: relative;
}

.showcase__header {
  display: flex;
  color: $secondary-color;
  gap: 30px;
  @media only screen and (max-width: $mobile) {
    gap: 10px;
  }
  h2 {
    display: inline-block;
    text-align: center;
    color: $secondary-color;
    position: relative;
    &::before {
      content: "";
      display: block;
      margin-top: -0.2em;
    }

    &::after {
      content: "";
      display: block;
      margin-bottom: 0.2em;
    }
  }
}

.showcase__header__illustration {
  flex-grow: 1;
  position: relative;
}

.showcase__header__illustration__inner {
  width: 0;
  position: absolute;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.showcase__header__illustration--left > * {
  right: 0;
  transform: scaleX(-1);
}

.showcase__header__illustration--right > * {
  left: 0;
}
.showcase__teams {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 60px;
  row-gap: 120px;
  margin: 120px auto;

  @media only screen and (max-width: 1024px) {
    margin: 60px auto;
  }
  @media only screen and (max-width: $mobile) {
    margin: 30px auto;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 60px;
  }
  @media only screen and (max-width: $mobile) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 30px;
  }
}

.showcase__team {
  text-align: justify;

  h3 {
    font-size: 24px;
    margin: 20px 0 14px 0;
    color: $secondary-color;
    font-weight: bold;
    text-transform: uppercase;
    &::before {
      content: "{";
    }
    &::after {
      content: "}";
    }
  }
  p {
    font-size: 20px;
    color: $secondary-color;
  }
}

.showcase__team__photo {
  background: blue;
  width: 100%;
  position: relative;
  display: block;
  cursor: pointer;
  &::before {
    content: "";
    display: block;
    position: relative;
    padding-top: 100%;
  }
  &::after {
    content: "";

    @media only screen and (max-width: $mobile) {
      width: 25px;
      height: 25px;
    }
  }
  .showcase__team__cover {
    width: 100%;
    height: 100%;
    filter: grayscale(100);
  }
  .showcase__team__cover:hover {
    filter: grayscale(0);
  }
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
  }
}

.showcase__actions {
  display: flex;
  align-items: center;
  @media only screen and (max-width: $mobile) {
    flex-direction: column;
  }
  text-align: center;

  img {
    width: 5vw;
    object-fit: contain;
    margin: 0 8vw;

    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }
}

.showcase__actions__action {
  display: block;
  // flex-grow: 0.5;
  color: $secondary-color;
  flex: 1;
  h3 {
    font-size: 1.75rem;
    padding-bottom: 1rem;
    font-weight: 700;
    margin: 2rem 0;
  }
}

.showcase__actions__illustration--horizontal {
  display: none;
  width: 100%;
  height: 50px;
  background: magenta;
  @media only screen and (max-width: $mobile) {
    display: block;
  }
}

.divider-container {
  display: none;
  justify-content: center;
  margin: 4rem 0;

  .mobile-divider {
    display: none;
  }
  @media only screen and (max-width: $mobile) {
    display: flex;

    .mobile-divider {
      display: flex;
    }
  }
}
