@import "../../styles/base.scss";

.landing {
  padding-top: 118px;
  box-sizing: border-box;
  height: 100vh;
  background-image: url("../../assets/images/website_texture.png");
  border-bottom: 2px solid black;
  overflow-x: hidden;
}

.desktopLandingText {
  .landingTextWrapper {
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-left: 8vw;
    text-align: left;

    img {
      width: clamp(160px, 25vw, 300px);
    }

    .firstLine {
      line-height: 0.8;
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: clamp(64px, 12vw, 200px);
    }

    .secondLine {
      display: flex;
      align-items: flex-start;
      gap: 3vw;

      h1 {
        line-height: 0.8;
        font-family: $heading-font;
        font-size: clamp(64px, 12vw, 300px);
        text-align: left;
        text-transform: uppercase;
      }
    }

    .subtitleWrapper {
      font-family: $p-font;
      font-size: 32px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      gap: 1vw;
      max-width: 40vw;
      margin-top: 0.5em;

      a {
        color: inherit;
        pointer-events: all;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.mobileLandingText {
  .landingTextWrapper {
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-left: 8vw;
    text-align: left;

    .subtitleWrapper {
      margin-top: 2rem;
      font-weight: 600;
      max-width: 60vw;
      display: flex;
      flex-direction: column;
      gap: 3vw;

      a {
        color: inherit;
      }
    }

    img {
      width: clamp(160px, 25vw, 300px);
    }

    h1 {
      line-height: 0.8;
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: clamp(64px, 12vw, 200px);
    }
  }

  @media only screen and (min-width: 601px) {
    display: none;
  }
}

.scene3Dwrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(-2px);

  @media only screen and (max-width: 600px) {
    pointer-events: none;
  }
}

.scene3D {
  width: 100%;
  height: 100%;
  position: absolute;
}
