$primary-color: #424242;
$secondary-color: #F0F4C3;
$accent-color-1: #9bb7ff;
$accent-color-2: #43a047;

$mobile: 600px;

$desktop: 960px;

$heading-font: "Neue Plak", sans-serif;

$p-font: "Space Grotesk", sans-serif;

$sub-font: "Inter", sans-serif;

$link-font: "NB International Mono", sans-serif;

$desktop-padding: 8vw;

$mobile-padding: 10vw;

$desktop-stroke: 2px #424242 solid;

$mobile-stroke: 1px #424242 solid;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Neue Plak";
  src: url("../assets/fonts/NeuePlakW01.otf") format("opentype");
}

@font-face {
  font-family: "NB International Mono";
  src: url("../assets/fonts/NBInternationalMonoWebfont.woff") format("woff");
}

html {
  scroll-behavior: auto;
  scroll-padding-top: 4rem;

  @media only screen and (min-width: $mobile) {
    scroll-padding-top: 7rem;
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0px;
  padding: 0px;
  color: $primary-color;
  background-color: $secondary-color;
  font-family: sans-serif;
}

h1 {
  color: $primary-color;
  font-family: $heading-font;
  font-size: clamp(60px, 25vw, 300px);
}

h2 {
  color: $primary-color;
  font-family: $heading-font;
  font-size: clamp(48px, 12vw, 160px);
  line-height: 90%;
  text-transform: uppercase;
}

p,
li {
  color: $primary-color;
  font-family: $p-font;
  font-size: 0.8rem;

  @media only screen and (min-width: $mobile) {
    font-size: 1.2rem;
  }
  @media only screen and (min-width: $desktop) {
    font-size: 1.5rem;
  }
}

span {
  color: $primary-color;
  font-family: $p-font;
}
