@import '../../styles/base.scss';


.faqs {
    text-align: left;
    background-color: #E7E5D1;

    .faq {
      border-top: $desktop-stroke;
      padding: 1.5rem $desktop-padding;
      cursor: pointer;
      color: #424242;

      @media (max-width: $mobile) {
        border-top: $mobile-stroke;
      }

      .faq-question {
        position: relative;
        font-size: 1.5rem;
        padding-right: 80px;
        font-family: $p-font;
        font-weight: 500;
      
        transition: all 0.2s ease;
      }
      .faq-question::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
      
        background-image: url("../../assets/images/icon-plus.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.2s ease;
      }
      .faq-answer {
        opacity: 0;
        max-height: 0;
        overflow-y: hidden;
        transition: all 0.2s ease;
        font-family: $p-font;
        font-size: 1.25rem;
        line-height: 1.5;
      }
    }
    .faq.open {

      .faq-question {
        margin-bottom: 15px;
      }
      .faq-question::after {
        transform: translateY(-50%) rotate(135deg);
      }
      .faq-answer {
        max-height: 1000px;
        opacity: 1;
      }
    }
  }

  @media (max-width: mobile) {
    #faq {
      padding-top: 3.5rem;
    }
    .faq-title {
      font-size: 3rem;
      letter-spacing: 0.05rem;
      margin: 0 auto;
      -webkit-text-stroke: 1px #e0d9d5;
      color: #292929;
    }
    .faqs .faq .faq-question {
      font-size: 1.25rem;
    }
    .faqs .faq .faq-answer {
      font-size: 1rem;
    }
    #faq:target {
      padding-top: calc(3.5rem + 70px);
    }
  }