@import "./base.scss";

.App {
  // padding-top: 6rem;
}

#delay {
  opacity: 0;
}

#animation-container {
    z-index: 1000000;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.showcase-opening {
  display: flex;
}

.mobile-opening {
  display: none;
}

@media only screen and (max-width: $mobile) {
  .showcase-opening {
    display: none;
  }
  .mobile-opening {
    display: flex;
  }
}
