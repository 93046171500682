@import "../../styles/base.scss";

.showcase__buttonAsLink {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $accent-color-1;
  height: 54px;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: $primary-color;
  border-radius: 999px;
  border: 2px solid $secondary-color;
  margin-bottom: 32px;
  font-weight: 500;

  p {
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
  }

  @media only screen and (min-width: $mobile) {
    width: auto;
    padding: 0 32px;
    margin-bottom: 0;
  }
}

.showcase__buttonAsLink:hover,
.showcase__buttonAsLink:active {
  border: 2px solid #f0f4c3;
  background: $primary-color;

  p {
    color: #f0f4c3;
  }
}
