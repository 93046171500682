@import '../../styles/base.scss';

.navbar {
    background-color: #E7E5D1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 7.2rem;
    padding: 0 $desktop-padding;
    background-image: url("../../assets/images/website_texture.png");
    border-bottom: $desktop-stroke;
    position: fixed;
    top: 0;
    left: 0;
    width: 84vw;
    z-index: 200;

    img {
        height: 3rem;
        object-fit: contain;
    }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        li {
            font-family: $p-font;
            font-size: 1.25rem;
            list-style-type: none;
            margin-left: 1.5rem;
            line-height: 3rem;

            a {
                text-decoration: none;
                color: #424242;
            }
            .pill {
                border-radius: 200px;
                border: $desktop-stroke;
                
                a {
                    
                    padding: 0.5rem 1.25rem;
                }
            }
            .pill:hover {
                background-color: #9BB7FF;
            }
        }
    }
    .menu {
        display: none;
    }
    .mobile-container {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #E7E5D1;
        z-index: 100;
        background-image: url("../../assets/images/website_texture.png");

        .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 2rem $mobile-padding;

            .menu {
                cursor: pointer;
                height: 2.5rem;
                object-fit: contain;
                z-index: 100;
            }
        }
        a {
            text-decoration: none;

            .section {
                display: flex;
                border-top: $mobile-stroke;
                padding: 2vh 8vw;
    
                p {
                    font-family: $p-font;
                    font-weight: 500;
                    color: #424242;
                    font-size: 3vh;
                }
            }
        }
        .border-bottom {
            border-top: $mobile-stroke
        }
        .pill {
            display: flex;
            padding: 4vh 0;
            align-self: center;

            a {
                font-family: $p-font;
                font-weight: 500;
                font-size: 3vh;
                border-radius: 40px;
                border: 1.5px solid #424242;
                padding: 0.5rem 1.25rem;
                text-decoration: none;
                color: #424242;
            }
        }
        .mobile-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: #E7E5D1;
            border-top: $mobile-stroke;
            padding: 2rem 4vw;
            position: fixed;
            width: 92vw;
            bottom: 0;
            background-image: url("../../assets/images/website_texture.png");
        
            .footer-left {
                display: flex;
                flex-direction: row;
                font-family: $sub-font;
                align-items: center;
        
                p {
                    margin-right: 0.5rem;
                    font-size: 12px;
                }
                .footer-logo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
        
                    .heart-icon {
                        height: 0.5rem;
                    }
                    .dco-footer-logo {
                        margin-left: 0.5rem;
                        height: 0.5rem;
                    }
                }
            }
            .footer-right {
                margin-bottom: 0.2rem;
        
                a {
                    text-decoration: underline;
                    color: #424242;
                    font-size: 10px;
                }
            }
            
        }

    }
    @media only screen and (max-width: $desktop) {
        ul {
            li {
                font-size: 0.9rem;
                margin-left: clamp(0.25rem, 1vw, 1rem);
            }
        }
    }
    @media only screen and (max-width: $mobile) {
        ul {
            display: none;
        }
        .menu {
            cursor: pointer;
            display: block;
            height: 2.5rem;
            object-fit: contain;
        }
    }

}

