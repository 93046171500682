@import "../../styles/base.scss";

.overview {
  padding: 7.5rem 0 7.5rem 8vw;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  //   width: calc(100vw - 8vw); I removed this b/c it was causing horizontal scroll - leann
  overflow-x: hidden;
  background-image: url(../../assets/images/website_texture.png);
  border-top: $desktop-stroke;
  border-bottom: $desktop-stroke;

  h2 {
    transform: translateX(20px);
    line-height: 100%;
  }

  &__list {
    text-align: left;
  }
  ul {
    height: 280px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: space-between;
    justify-content: space-between;
    margin-top: 3rem;
    li {
      display: flex;
      align-items: center;
      img {
        margin-right: 24px;
        width: 2.4rem;
      }
    }
  }

  @media only screen and (max-width: $desktop) {
    ul {
      height: 240px;
      li img {
        margin-right: 18px;
        width: 1.4rem;
      }
    }
  }

  @media only screen and (max-width: $mobile) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
    // width: 100vw; caused horizontal scroll on mobile

    &__list {
      padding: 0 10vw;
    }
    h2 {
      width: 100%;
      transform: none;
      line-height: 100%;
      text-align: center;
    }
    ul {
      height: 160px;
    }
    .hidden {
      display: none;
    }
  }
}
