@import "../../styles/base.scss";

.showcase__articles {
  background-image: url("../../assets/images/website_texture.png");
  padding: 120px;
  p {
    margin: 60px 0;
  }
  @media only screen and (max-width: 1024px) {
    padding: 60px;
  }
  @media only screen and (max-width: $mobile) {
    padding: 30px;
    p {
      margin: 30px 0;
      font-size: 16px;
    }
  }
}

.showcase__articles__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.showcase__button__wrapper {
  display: flex;
  justify-content: center;
}

.showcase__flex {
  flex-grow: 1;
}

.showcase__articles__article {
  display: flex;
  flex-direction: column;

  border: 2px solid $primary-color;
  // padding: 30px 30px 80px 30px;
  position: relative;
  h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: $p-font;
  }
  p {
    font-size: 20px;
    margin: initial;
    margin-bottom: 60px;
    text-align: justify;

    @media only screen and (max-width: 800px) {
      margin-bottom: 30px;
    }
  }

  a p {
    margin: 0;
    font-size: 16px;
  }
}

.showcase__articles__illustration__box {
  flex-basis: 60px;
  flex-grow: 1;
  box-sizing: border-box;
  position: relative;
  border: 2px solid $primary-color;
  margin-left: -2px;
  border-radius: 0;
  &::before {
    content: "";
    display: block;
    position: relative;
    padding-top: 100%;
  }
}
