@import "../../styles/base.scss";

html {
  height: 100%;
  width: 100%;
}

.page {
  background-image: url(../../assets/images/website_texture.png);
  background-repeat: repeat;
  overflow-x: hidden;
}

.lower {
  display: flex;
  flex-direction: column;
}

.shapes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;

  .rect {
    height: calc(5.5vw + 2px);
    width: calc(11vw + 4px);
  }
  .circle {
    border: $mobile-stroke;
    border-radius: 50%;
    background-color: #9bb7ff;
    height: 5.5vw;
    width: 5.5vw;
    margin-left: -0.5px;
    margin-right: -0.5px;
  }
  #circle1 {
    animation-name: trans1;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-delay: 0;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes trans1 {
    0% {
      border-radius: 100%;
    }
    25% {
      border-radius: 0%;
    }
    50% {
      border-radius: 0%;
    }
    75% {
      border-radius: 100%;
    }
  }

  #circle2 {
    animation-name: trans3;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-delay: 1s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes trans3 {
    0% {
      border-radius: 100%;
    }
    25% {
      border-radius: 0%;
    }
    50% {
      border-radius: 0%;
    }
    75% {
      border-radius: 100%;
    }
  }

  .oval {
    border: $mobile-stroke;
    background-color: #43a047;
    height: 5.5vw;
    width: calc(11vw + 2px);
    border-radius: 25%/50%;
    margin-left: -0.5px;
    margin-right: -0.5px;
  }

  #oval1 {
    animation-name: trans2;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-delay: 2s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes trans2 {
    0% {
      width: calc(11vw + 2px);
      border-radius: 25%/50%;
    }
    15% {
      width: 5.5vw;
      border-radius: 100%;
      transform: translate3d(0, 0, 0);
    }
    35% {
      width: 5.5vw;
      border-radius: 100%;
      transform: translate3d(5.5vw, 0, 0);
    }
    55% {
      width: 5.5vw;
      border-radius: 100%;
      transform: translate3d(0, 0, 0);
    }
    70% {
      border-radius: 25%/50%;
      width: calc(11vw + 2px);
    }
  }

  #oval2 {
    animation-name: trans4;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-delay: 0;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }
  @keyframes trans4 {
    0% {
      width: calc(11vw + 2px);
      border-radius: 25%/50%;
    }
    15% {
      transform: translate3d(5.5vw, 0, 0);
      width: 5.5vw;
      border-radius: 100%;
    }
    35% {
      width: 5.5vw;
      transform: translate3d(0, 0, 0);
      border-radius: 100%;
    }
    55% {
      width: 5.5vw;
      transform: translate3d(5.5vw, 0, 0);
      border-radius: 100%;
    }
    70% {
      transform: translate3d(0, 0, 0);
      border-radius: 25%/50%;
      width: calc(11vw + 2px);
    }
  }

  .square {
    border: $mobile-stroke;
    width: 5.5vw;
    height: 5.5vw;
    margin-left: -0.5px;
    margin-right: -0.5px;
  }
}

.title {
  padding-top: 4rem;
  margin-bottom: 2rem;

  #left {
    text-align: start;
    line-height: 12vw;
    margin-left: -0.4rem;
    margin-top: 0rem;
    height: 18vw;
  }
  #right {
    margin-top: -0.5em;
    text-align: end;
    margin-right: -0.4rem;
    padding-top: 0rem;
  }
}

.body {
  text-align: justify;
  font-family: $p-font;
  display: flex;
  flex-direction: column;

  #leftText {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
    padding-top: 1vh;
    margin-bottom: 0rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.2rem;
  }
  #rightText {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
    padding-top: 1vh;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 401px) {
  .title {
    #left {
      margin-left: -0.5rem;
    }
    #right {
      margin-right: -0.5rem;
    }
  }
}

@media screen and (min-width: $mobile) {
  .title {
    padding-top: 7.5rem;

    #left {
      margin-left: -0.7rem;
    }
    #right {
      margin-right: -0.7rem;
    }
  }
}

@media screen and (min-width: $desktop) {
  .title {
    margin-bottom: 3.75rem;

    #left {
      text-align: start;
      margin-left: -1rem;
    }
    #right {
      margin-top: -0.5em;
      text-align: end;
      margin-right: -1rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
      margin-bottom: 0rem;
    }
  }

  .body {
    display: flex;
    flex-direction: row;
    p {
      width: 42vw;
      margin-left: 1rem;
    }
    #leftText {
      padding-left: $desktop-padding;
      padding-right: $desktop-padding/2;
      margin-bottom: 4vw;
    }
    #rightText {
      padding-left: $desktop-padding/2;
      padding-right: $desktop-padding;
      margin-bottom: 4vw;
    }
  }
  .shapes {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4.4vw;
    padding-top: 1vw;
    .circle {
      border: $desktop-stroke;
      border-radius: 50%;
      background-color: #9bb7ff;
      margin-left: -1px;
      margin-right: -1px;
    }
    .oval {
      border: $desktop-stroke;
      background-color: #43a047;
      margin-left: -1px;
      margin-right: -1px;
      border-radius: 25%/50%;
    }
    .square {
      border: $desktop-stroke;
      margin-left: -1px;
      margin-right: -1px;
    }
  }
}

@media screen and (min-width: 1001px) {
  .title {
    #left {
      margin-left: -1.3rem;
    }
    #right {
      margin-right: -1.2rem;
    }
  }
}

@media screen and (min-width: 1301px) {
  .title {
    #left {
      margin-left: -1.7rem;
    }
    #right {
      margin-right: -1.6rem;
    }
  }
}
